
const CLIENT_ID = '0oahtapjf6E2NEp4g1d7';
const ISSUER = 'https://intelliscriptaccess.oktapreview.com/oauth2/default';
const OKTA_TESTING_DISABLEHTTPSCHECK = false;
const REDIRECT_URI = `${window.location.origin}/login/callback`;
const SCOPES = ['openid', 'offline_access', 'profile'];

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: SCOPES,
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
  resourceServer: {
    messagesUrl: 'http://localhost:8000/api/messages',
  },
};