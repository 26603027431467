import { LoginButton } from "../LoginButton/LoginButton";

function Home() {
  
  return (
    <div className="flex justify-between m-6 items-center">
      <h1 className="text-3xl font-serif">WELCOME</h1>
      <LoginButton />
    </div>
  );
}

export { Home };
