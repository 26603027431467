import { Route } from "react-router-dom";
import { LoginCallback, SecureRoute } from "@okta/okta-react";
import { Home } from "../modules/Home/Home";

export const AppRoutes = () => {
  return (
    <>
      <Route path="/login/callback" component={LoginCallback} />
      <SecureRoute exact path="/" component={Home} />
    </>
  );
};
