import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { useHistory } from "react-router-dom";
import { AppRoutes } from './Components/AppRoutes';
import config from "./config";

const oktaAuth = new OktaAuth(config.oidc);

function App() {
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(
      toRelativeUrl(originalUri || "/", "https://localhost:8080/login/callback")
    );
  };

  return (
    <Security restoreOriginalUri={restoreOriginalUri} oktaAuth={oktaAuth}>
      <main>
        <AppRoutes />
      </main>
    </Security>
  );
}

export default App;
