import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";

export const useAuthUser = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState() as any;

  const loginWithRedirect = () =>
    oktaAuth.signInWithRedirect({ originalUri: "/" });

  useEffect(() => {
    if (authState && authState.isAuthenticated === false) {
      loginWithRedirect();
    }
    const getUser = async () => {
      try {
        const res = await oktaAuth.getUser();
        setUserInfo(res);
      } catch (error) {
        console.log(error);
      }
    };

    authState?.isAuthenticated && getUser();
  }, [authState, oktaAuth]);
  return userInfo;
};
