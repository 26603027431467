import { useOktaAuth } from "@okta/okta-react";
import { useAuthUser } from "../../Hooks/useAuthUser";

export const LoginButton = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const user = useAuthUser();

  const loginWithRedirect = () =>
    oktaAuth.signInWithRedirect({ originalUri: "/" });

  const logOut = () => oktaAuth.signOut();

  const buttonText = authState?.isAuthenticated ? "Sign-out" : "Sign-in";

  const btnLogic = authState?.isAuthenticated ? logOut : loginWithRedirect;

  return (
    <div>
      <span className="mr-2 text-gray-800">{user?.name}</span>
      <button className="border border-gray-500 p-2 rounded" onClick={btnLogic}>{buttonText}</button>
    </div>
  );
};
